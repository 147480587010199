import * as d3 from "d3";
import { curveMonotoneX } from "d3";
import { Data } from "./pricing-graph.types";

interface Props {
	data: Data[];
	xScale: d3.ScaleTime<number, number, never>;
	yScale: d3.ScaleLinear<number, number, never>;
	stopOffset: number | null;
}

export const LinePlot = ({ data, xScale, yScale, stopOffset }: Props) => {
	// line function
	const line = d3
		.line<Data>()
		.x((d: Data) => xScale(d.date))
		.y((d: Data) => yScale(d.value))
		.curve(curveMonotoneX);

	/**
	 * Gradient does not apply properly when objectBoundingBox is used. Horizontal line is not visible
	 * This function adds 0.001 pixel jittery to the y-coord of last data point
	 */
	function generateLine(
		yScale: d3.ScaleLinear<number, number, never>,
		data: Data[],
		line: d3.Line<Data>,
	) {
		if (!data || data.length === 0) return "";

		const lastYCoordinate = yScale(data[data.length - 1].value);
		const adjustedYCoordinate = (lastYCoordinate + 0.001).toFixed(3);
		const d = line(data);

		if (!d) return "";
		return d.replace(/,([^,]*)$/, "," + adjustedYCoordinate);
	}

	// Create a linear gradient
	const gradientId = "gradient";
	const targetPoint = stopOffset !== null && typeof stopOffset === "number" && !isNaN(stopOffset) ? `${stopOffset * 100}%` : "0%";
	const gradient = (
		<linearGradient id={gradientId} x1="0%" y1="0%" x2="100%">
			<stop offset="0%" stopColor="lightgrey" />
			<stop offset={targetPoint} stopColor="lightgrey" />
			<stop offset={targetPoint} stopColor="#175963" />
		</linearGradient>
	);

	return (
		<g className="line">
			<defs>{gradient}</defs>
			<path
				fill="none"
				stroke={stopOffset ? `url(#${gradientId})` : "#175963"}
				strokeWidth="1.5"
				d={generateLine(yScale, data, line)}
			/>
		</g>
	);
};
