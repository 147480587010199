import { createContext, useContext } from "react";

const ChartContext = createContext(null);
export const useDimensionsContext = () => useContext(ChartContext);

const Chart = ({ svgRef, dimensions, children }: any) => {
	return (
		<ChartContext.Provider value={dimensions}>
			<svg
				className="Chart"
				ref={svgRef}
				width={dimensions.width}
				height={dimensions.height}
			>
				<g
					transform={`translate(${dimensions.marginLeft}, ${dimensions.marginTop})`}
				>
					<defs>
						<clipPath className="clip-path" id="clip">
							<rect
								x="0"
								y="0"
								width={dimensions.boundedWidth}
								height={dimensions.boundedHeight}
							/>
						</clipPath>
						<clipPath id="price-indicator-clip-path">
							<rect
								x="-120"
								y="-20"
								width={dimensions.boundedWidth + 120}
								height={dimensions.boundedHeight + 40}
							/>
						</clipPath>
						<clipPath id="listing-date-clip-path">
							<rect
								x="0"
								y="-20"
								width={dimensions.boundedWidth}
								height={dimensions.boundedHeight + 20}
							/>
						</clipPath>
						<clipPath id="price-legend-clip-path">
							<rect
								x="-40"
								y="-20"
								width={dimensions.boundedWidth + 80}
								height={dimensions.boundedHeight + 20}
							/>
						</clipPath>
					</defs>
					{children}
				</g>
			</svg>
		</ChartContext.Provider>
	);
};

export default Chart;
