import * as d3 from "d3";
import { addThousandSeparatorWithDP } from "./../../../lib/number";
import usePricingGraphLegend from "./hooks/use-pricing-graph-legend";
import { Dimensions, IntervalData } from "./pricing-graph.types";

interface Props {
	mouseX: number | null;
	data: IntervalData;
	dimensions: Dimensions;
	xScale: d3.ScaleTime<number, number, never>;
	yScale: d3.ScaleLinear<number, number, never>;
	listingDate?: string | null;
	isAskGraph: boolean;
	toggleIndicator: {
		ask: boolean;
		bid: boolean;
		trades: boolean;
	};
}

export const MobilePriceLegend = ({
	mouseX,
	data,
	dimensions,
	xScale,
	yScale,
	isAskGraph,
	toggleIndicator,
}: Props) => {
	const { legends } = usePricingGraphLegend({
		mouseX,
		data,
		dimensions,
		xScale,
		yScale,
		isAskGraph,
		toggleIndicator,
	});

	return (
		<div className="bg-[#F5FBFC] p-2 rounded flex flex-col gap-y-2">
			{legends.map((legend, index) => (
				<div key={index} className="flex justify-between items-center">
					<div className="flex items-center space-x-2">
						{legend.name === "Ask price" && isAskGraph && (
							<svg
								width="10"
								height="10"
								viewBox="0 0 10 10"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle
									cx="5"
									cy="5"
									r="2.5"
									stroke="#175963"
									strokeWidth="2"
								/>
							</svg>
						)}
						{legend.name === "Ask price" && !isAskGraph && (
							<svg
								width="10"
								height="10"
								viewBox="0 0 10 9"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1.28106 0.75L5.04502 7.45564L8.73177 0.75H1.28106Z"
									stroke="#AF1623"
									strokeWidth="1.5"
								/>
							</svg>
						)}
						{legend.name === "Bid price" && (
							<svg
								width="10"
								height="10"
								viewBox="0 0 10 10"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1.28106 8.25L5.04502 1.54436L8.73177 8.25H1.28106Z"
									stroke="#249567"
									strokeWidth="1.5"
								/>
							</svg>
						)}
						{legend.name === "Closed trade" && isAskGraph && (
							<svg
								width="10"
								height="10"
								viewBox="0 0 10 10"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect x="2" y="2" width="6" height="6" stroke="#4c5564" />
								<rect x="3.5" y="3.5" width="3" height="3" fill="#4c5564" />
							</svg>
						)}
						{legend.name === "Closed trade" && !isAskGraph && (
							<svg
								width="10"
								height="10"
								viewBox="0 0 10 10"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle
									cx="5"
									cy="5"
									r="2.5"
									stroke="#175963"
									strokeWidth={2}
								/>
							</svg>
						)}
						<span className="text-[#4B5563] text-sm font-semibold">
							{legend.name == "Closed trade" && !isAskGraph
								? "Market price"
								: legend.name}
						</span>
					</div>
					<span className="text-[#2C5862] text-sm font-semibold">
						{legend?.data?.value
							? addThousandSeparatorWithDP(Number(legend.data.value), 2)
							: ""}
					</span>
				</div>
			))}
		</div>
	);
};
