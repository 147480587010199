import * as React from "react";

import { cn } from "../../lib/utils";

export interface LeftLabeledInputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	label: string;
	parentClassName?: string;
	labelClassName?: string;
}

const LeftLabeledInput = React.forwardRef<
	HTMLInputElement,
	LeftLabeledInputProps
>(
	(
		{ className, label, parentClassName, labelClassName, type, ...props },
		ref,
	) => {

		return (
			<div
				className={cn(
					"relative flex items-center",
					{
						"cursor-not-allowed": props.disabled,
					},
					parentClassName,
				)}
			>
				<fieldset
					disabled={props.disabled}
					className={cn(
						"h-10 rounded-tl-md rounded-bl-md border border-slate-200 px-3 py-2",
						{
							"disabled:cursor-not-allowed disabled:opacity-50": props.disabled,
						},
						labelClassName,
					)}
				>
					{label}
				</fieldset>
				<input
					type={type}
					className={cn(
						// keeping this the same as @/components/ui/input.tsx
						"flex h-10 w-full border-slate-200 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300",
						{
							"border border-slate-200 rounded-tr-md rounded-br-md border-l-0":
								true,
						},
						className,
					)}
					ref={ref}
					{...props}
				/>
			</div>
		);
	},
);
LeftLabeledInput.displayName = "LeftLabeledInput";

export { LeftLabeledInput };
