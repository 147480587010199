import * as d3 from "d3";
import { Moment } from "moment";
import { addThousandSeparatorWithDP } from "./../../../lib/number";
import usePricingGraphLegend from "./hooks/use-pricing-graph-legend";
import { Dimensions, INTERVAL, IntervalData } from "./pricing-graph.types";

interface Props {
	mouseX: number | null;
	data: IntervalData;
	dimensions: Dimensions;
	xScale: d3.ScaleTime<number, number, never>;
	yScale: d3.ScaleLinear<number, number, never>;
	listingDate?: string | null;
	intervalSelected: INTERVAL;
	isAskGraph: boolean;
	yAxisPixelOffset?: number;
	toggleIndicator: {
		ask: boolean;
		bid: boolean;
		trades: boolean;
	};
}

export const PriceLegend = ({
	mouseX,
	data,
	dimensions,
	xScale,
	yScale,
	intervalSelected,
	isAskGraph,
	toggleIndicator,
}: Props) => {
	const {
		invertedDate,
		legends,
		closestDataPoint,
		closestDataPointIsLine,
		xDataTranslation,
		yDataTranslation,
	} = usePricingGraphLegend({
		mouseX,
		data,
		dimensions,
		xScale,
		yScale,
		isAskGraph,
		toggleIndicator,
	});
	const marginRow = 24;

	function formatDate(date: Moment) {
		if (intervalSelected == INTERVAL.MONTHLY) {
			return date.format("MMM YYYY");
		} else if (intervalSelected == INTERVAL.WEEKLY) {
			return date.format("D MMM YYYY");
		} else return date.format("D MMM YYYY");
	}

	return (
		<>
			{invertedDate &&
				closestDataPoint &&
				xDataTranslation !== null &&
				yDataTranslation !== null && (
					<g>
						{/* vertical dotted line */}
						<line
							x1={xDataTranslation}
							y1={0}
							x2={xDataTranslation}
							y2={dimensions.boundedHeight}
							stroke="#9CA3AF"
							strokeWidth={0.5}
							strokeDasharray="3,3"
						/>

						{closestDataPointIsLine && (
							<circle
								cx={xDataTranslation}
								cy={yDataTranslation}
								r="5"
								fill="#00c4ac"
								stroke="white"
								strokeWidth={2}
							/>
						)}

						{/* date tooltip */}
						<svg
							y={-10}
							x={xDataTranslation - 36.5}
							width="75"
							height="60"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g width="75" height="20">
								<rect
									style={{
										filter:
											"drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.04)) drop-shadow(0px 30px 18px rgba(0, 0, 0, 0.02))",
									}}
									x="0.5"
									width="74"
									y="0.5"
									height="20"
									fill="white"
									stroke="#9CA3AF"
									strokeWidth="1"
									rx="4"
								/>
								<text
									fill="#9CA3AF"
									dominantBaseline="middle"
									fontSize="10"
									x="50%"
									y="11"
									textAnchor="middle"
								>
									{formatDate(closestDataPoint.date)}
								</text>
							</g>
						</svg>
					</g>
				)}
			<svg className="hidden sm:block" width="250" height="200">
				<g transform={`translate(30, 40)`}>
					<rect
						y={-20}
						x={-8}
						width={165}
						height={
							legends.filter((legend) => !!legend.data).length * marginRow + 5
						}
						rx="8"
						fill="white"
						fillOpacity={0.8}
					></rect>
					{legends.map(
						(legend, index) =>
							legend.data && (
								<g key={index} transform={`translate(0, ${index * marginRow})`}>
									<g transform={`translate(0, -9)`}>
										{legend.name == "Ask price" && isAskGraph && (
											<svg
												width="10"
												height="10"
												viewBox="0 0 6 6"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<circle cx="3" cy="3" r="1.5" stroke="#175963" />
											</svg>
										)}
										{legend.name == "Ask price" && !isAskGraph && (
											<svg
												width="10"
												height="10"
												viewBox="0 0 10 9"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M1.28106 0.75L5.04502 7.45564L8.73177 0.75H1.28106Z"
													stroke="#AF1623"
													strokeWidth="1.5"
												/>
											</svg>
										)}

										{legend.name == "Bid price" && (
											<svg
												width="10"
												height="10"
												viewBox="0 0 10 9"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M1.28106 8.25L5.04502 1.54436L8.73177 8.25H1.28106Z"
													stroke="#249567"
													strokeWidth="1.5"
													transform="translate(0, -0.5)"
												/>
											</svg>
										)}

										{legend.name == "Closed trade" && isAskGraph && (
											<svg
												width="10"
												height="10"
												viewBox="0 0 10 10"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<rect
													x="2"
													y="2"
													width="6"
													height="6"
													stroke="#4c5564"
												/>
												<rect
													x="3.5"
													y="3.5"
													width="3"
													height="3"
													fill="#4c5564"
												/>
											</svg>
										)}

										{legend.name == "Closed trade" && !isAskGraph && (
											<svg
												width="10"
												height="10"
												viewBox="0 0 6 6"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<circle cx="3" cy="3" r="2" stroke="#4c5564" />
											</svg>
										)}
									</g>

									<g transform="translate(5,0)">
										<text x={10} style={{ fontSize: "12px", fill: "black" }}>
											{legend.name == "Closed trade" && !isAskGraph
												? "Market price"
												: legend.name}
										</text>

										<text x={90} style={{ fontSize: "12px", fill: "black" }}>
											{legend?.data?.value
												? addThousandSeparatorWithDP(
														Number(legend.data.value),
														2,
													)
												: ""}
										</text>
									</g>
								</g>
							),
					)}
				</g>
			</svg>
		</>
	);
};
