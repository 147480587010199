import { ResizeObserver } from "@juggle/resize-observer";
import { RefObject, useEffect, useRef, useState } from "react";
import { Dimensions } from "../pricing-graph.types";

export const useGraphDimensions = (
	passedSettings: Partial<Dimensions>,
): [RefObject<HTMLDivElement>, Dimensions] => {
	const ref = useRef<HTMLDivElement>(null);
	const dimensions = combineGraphDimensions(passedSettings);

	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);

	useEffect(() => {
		if (dimensions.width && dimensions.height) {
			return;
		}

		const element = ref.current;

		if (!element) return;

		const resizeObserver = new ResizeObserver((entries) => {
			if (!Array.isArray(entries)) return;
			if (!entries.length) return;

			const entry = entries[0];
			const [{ inlineSize, blockSize }] = entry.contentBoxSize;

			if (width != inlineSize) {
				setWidth(inlineSize);
			}
			if (height != blockSize) {
				setHeight(blockSize);
			}
		});
		resizeObserver.observe(element);

		return () => resizeObserver.unobserve(element);
	}, [ref]);

	const newSettings = combineGraphDimensions({
		...dimensions,
		width: dimensions.width ?? width,
		height: dimensions.height ?? height,
	});

	return [ref, newSettings as Dimensions];
};

const combineGraphDimensions = (dimensions: Partial<Dimensions>) => {
	const parsedDimensions = {
		...dimensions,
		marginTop: (dimensions.marginTop ?? 0) + 5,
		marginRight: (dimensions.marginRight ?? 0) + 0,
		marginBottom: (dimensions.marginBottom ?? 0) + 10,
		marginLeft: (dimensions.marginLeft ?? 0) + 0,
	};

	return {
		...parsedDimensions,
		boundedHeight: Math.max(
			(parsedDimensions.height ?? 0) -
				parsedDimensions.marginTop -
				parsedDimensions.marginBottom,
			0,
		),
		boundedWidth: Math.max(
			(parsedDimensions.width ?? 0) -
				parsedDimensions.marginLeft -
				parsedDimensions.marginRight,
			0,
		),
	};
};
