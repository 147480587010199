// Inspired by shadcn-ui/badge
import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "../../lib/utils";

const pillVariants = cva(
	"inline-flex items-center rounded-full border gap-x-2 border-slate-200 px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-slate-950 focus:ring-offset-2 dark:border-slate-800 dark:focus:ring-slate-300",
	{
		variants: {
			variant: {
				default:
					"border-transparent bg-alta-accent-blue text-slate-600 hover:bg-alta-accent-blue/80 dark:bg-slate-50 dark:text-slate-900 dark:hover:bg-slate-50/80",
				secondary:
					"border-transparent bg-slate-100 text-slate-900 hover:bg-slate-100/80 dark:bg-slate-800 dark:text-slate-50 dark:hover:bg-slate-800/80",
				destructive:
					"border-transparent bg-red-500 text-slate-50 hover:bg-red-500/80 dark:bg-red-900 dark:text-slate-50 dark:hover:bg-red-900/80",
				outline: "text-slate-950 dark:text-slate-50",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	},
);

export interface PillProps
	extends React.HTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof pillVariants> {
	icon?: React.ReactNode;
}

function Pill({ className, variant, icon, children, ...props }: PillProps) {
	return (
		<button
			type="button"
			className={cn(pillVariants({ variant }), className)}
			{...props}
		>
			{children}
			{icon}
		</button>
	);
}

export { Pill, pillVariants };
