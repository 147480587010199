import * as d3 from "d3";
import { curveMonotoneX } from "d3";
import { Data } from "./pricing-graph.types";

interface Props {
	data: Data[];
	xScale: d3.ScaleTime<number, number, never>;
	yScale: d3.ScaleLinear<number, number, never>;
	height: number;
}

export const BackgroundUnderLinePlot = ({
	data,
	xScale,
	yScale,
	height,
}: Props) => {
	const area = d3
		.area<Data>()
		.x((d: Data) => xScale(d.date))
		.y0(height)
		.y1((d: Data) => yScale(d.value))
		.curve(curveMonotoneX);

	const gradientId = "bgGradient";
	const gradient = (
		<linearGradient id={gradientId} x1="0%" y1="0%" x2="0%" y2="100%">
			{/* Background Gradient = alta-light-green in tailwind */}
			<stop offset="0%" stopColor="rgba(64, 193, 172, 0.24)" />
			<stop offset="100%" stopColor="rgba(64, 193, 172, 0.04)" />
		</linearGradient>
	);
	return (
		<g>
			<defs>{gradient}</defs>
			<path
				fill={`url(#${gradientId})`}
				d={area(data) as string}
				style={{ pointerEvents: "none" }}
			/>
		</g>
	);
};
