import * as React from "react";

import { cn } from "../../lib/utils";

interface LogoProps extends React.ImgHTMLAttributes<HTMLImageElement> {
	className?: string;
	url?: string;
	name: string;
}

const Logo = React.forwardRef<
	HTMLImageElement,
	LogoProps
>(({ className, url, name, ...props }, ref) => (
	url
		? (
			<img
				ref={ref}
				className={cn("rounded-md border-2 border-slate-200", className)}
				src={url}
				{...props}
			/>)
		: (
			<div
				ref={ref}
				className={cn(
					"flex items-center justify-center border-2 border-slate-200 rounded-md bg-alta-dark-green text-white",
					className,
				)}
				{...props}
			>
				{name.slice(0, 2).toUpperCase()}
			</div>
		)
));

Logo.displayName = "Logo";

export { Logo };
