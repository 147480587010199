import { FieldValues, Path, UseFormClearErrors } from "react-hook-form";

export function addThousandSeparator(input: number): string {
	if (input)
		return input.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
	return "";
}

export function addThousandSeparatorToStr(input: string): string {
	if (input) return input.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
	return "";
}

export function removeThousandSeparator(input: number): number {
	if (input) return parseInt(input.toString().replace(",", ""), 10);
	throw new Error("Invalid input");
}

export function removeThousandSeparatorFromString(input: string): number {
	if (input) return parseInt(input.replace(",", ""), 10);
	throw new Error("Invalid input");
}

export function addThousandSeparatorWithDP(input: number, dp: number): string {
	if (!input || !dp) return "";

	if (input && typeof dp === "number") {
		const parts = input.toFixed(dp).split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return parts.join(".");
	} else return "";
}

export function formatAsThousands(input: number): string {
	if (input) return `${(input / 1000).toFixed(1)}K`;
	return "";
}

export function formatAsMillions(input: number): string {
	if (input) return `${(input / 1000000).toFixed(1)}M`;
	return "";
}

export function getChangeInFraction(prev: number, curr: number): number {
	// division by zero
	if (prev === 0) return 0;

	return (curr - prev) / prev;
}

export function formatAsDecimalString(
	input: number,
	decimalPoint: number = 0,
): string {
	// invalid params
	if (!input) return "0";
	if (decimalPoint < 0) return "0";

	return addThousandSeparatorToStr(input.toFixed(decimalPoint));
}

const handleOnChange = <T extends FieldValues>(
	e: React.ChangeEvent<HTMLInputElement>,
	fieldName: Path<T>,
	setValue: Function,
	clearErrors: UseFormClearErrors<T>,
) => {
	const regex = /([0-9]*[.]{0,1}[0-9]{0,2})/s;
	const validValue = e.target.value.match(regex)?.[0];
	setValue(fieldName, validValue);
	clearErrors(fieldName);
};

const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
	if (e.key === "e" || e.key === "+" || e.key === "-") {
		e.preventDefault();
	}
};

const handleOnKeyUp = <T extends FieldValues>(
	e: React.KeyboardEvent<HTMLInputElement>,
	setValue: Function,
	getValues: Function,
	fieldName: Path<T>,
) => {
	e.preventDefault();
	// regex to test string starting with zero '0'
	const regex = /^[0]([0-9]*[.]{0,1}[0-9]{0,2})/s;
	const inputPrice = getValues(fieldName)?.toString();
	const match = inputPrice?.match(regex);

	if (match && parseFloat(inputPrice) >= 1) {
		setValue(fieldName, Number(inputPrice.slice(1)));
	} else {
		setValue(fieldName, getValues(fieldName));
	}
};

export const numberInputHandler = {
	handleOnChange,
	handleOnKeyDown,
	handleOnKeyUp,
};
