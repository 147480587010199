import * as d3 from "d3";
import moment, { Moment } from "moment";
import { useMemo } from "react";
import useMediaQuery from "../../../lib/hooks/use-media-query";
import { Dimensions } from "./pricing-graph.types";

interface Props {
	dates: Moment[];
	boundedWidth: number;
	dimensions: Dimensions;
	showAxisLine?: boolean;
	showTickLine?: boolean;
	scale: d3.ScaleTime<number, number, never>;
	earliestDataDate: Moment;
}

export const XAxis = ({
	dates,
	boundedWidth,
	dimensions,
	showAxisLine = false,
	showTickLine = false,
	scale,
	earliestDataDate,
}: Props) => {
	// Maximum sm viewport
	const isMobile = useMediaQuery("(max-width: 640px)");
	const ticks = useMemo(() => {
		const leftDate = moment(scale.invert(scale.range()[0]));
		const rightDate = moment(scale.invert(scale.range()[1]));
		const difference = rightDate.diff(leftDate, "months");

		let interval: number = 1;
		let showYearOnly: boolean = false;
		if (difference >= 36) {
			interval = 12;
			showYearOnly = true;
		} else if (difference >= 24) {
			interval = 6;
		} else if (difference >= 12) {
			interval = 3;
		} else {
			interval = 2;
		}

		return scale
			.ticks((d3.timeMonth.every(interval) ?? 0) as number)
			.map((value) => {
				let formattedValue = "";
				// Show empty string for past (before 1st data point) & future date
				if (
					moment(value).subtract(1, "day").isBefore(new Date()) &&
					moment(value).add(1, "day").isAfter(earliestDataDate)
				) {
					if (showYearOnly) formattedValue = d3.timeFormat("%Y")(value);
					else formattedValue = d3.timeFormat("%b %y")(value);
				}
				return {
					value,
					xOffset: scale(value),
					formattedValue: formattedValue,
				};
			});
	}, [dates, boundedWidth, isMobile]);

	const d = ["M", 0, 0, "H", dimensions.boundedWidth];

	return (
		<g transform={`translate(0, ${dimensions.boundedHeight})`}>
			{showAxisLine && (
				<path d={d.join(" ")} fill="none" stroke="currentColor" />
			)}
			{ticks.map(({ value, xOffset, formattedValue }) => (
				<g key={value.toString()} transform={`translate(${xOffset}, 0)`}>
					{showTickLine && <line y2={6} stroke="currentColor" />}
					<text
						key={value.toString()}
						style={{
							fontSize: "10px",
							textAnchor: "middle",
							transform: "translateY(15px)",
						}}
					>
						{formattedValue}
					</text>
					<line
						y2={-dimensions.boundedHeight}
						stroke="#d3d3d3"
						strokeWidth={0.5}
					/>
				</g>
			))}
		</g>
	);
};
