import { Data } from "./pricing-graph.types";

interface Props {
	xScale: d3.ScaleTime<number, number, never>;
	yScale: d3.ScaleLinear<number, number, never>;
	data: Data;
	index: number;
	name: "ask" | "bid" | "trade";
	baseLength?: number;
	height?: number;
	isAskGraph: boolean;
}

const defaultTriangleParams = { x: 5, y: 5, baseLength: 6.5, height: 6.5 };
export function getUpwardEquilateralTrianglePoints({
	x,
	y,
	baseLength,
	height,
}: {
	x: number;
	y: number;
	baseLength: number;
	height: number;
} = defaultTriangleParams) {
	const x1 = x - baseLength / 2;
	const y1 = y + height / 2;
	const x2 = x + baseLength / 2;
	const y2 = y + height / 2;
	const x3 = x;
	const y3 = y - height / 2;

	return `${x1},${y1} ${x2},${y2} ${x3},${y3}`;
}
export function getDownwardEquilateralTrianglePoints({
	x,
	y,
	baseLength,
	height,
}: {
	x: number;
	y: number;
	baseLength: number;
	height: number;
} = defaultTriangleParams) {
	const x1 = x - baseLength / 2;
	const y1 = y - height / 2;
	const x2 = x + baseLength / 2;
	const y2 = y - height / 2;
	const x3 = x;
	const y3 = y + height / 2;

	return `${x1},${y1} ${x2},${y2} ${x3},${y3}`;
}

export const Marks = ({
	xScale,
	yScale,
	data,
	index,
	name,
	baseLength = 6.5,
	height = 6.5,
	isAskGraph,
}: Props) => {
	if (name === "bid") {
		return (
			<polygon
				key={`${name}-marks-${index}`}
				points={getUpwardEquilateralTrianglePoints({
					x: xScale(data.date),
					y: yScale(data.value),
					baseLength,
					height,
				})}
				fill="white"
				stroke="#249567"
				strokeWidth="1.5"
			/>
		);
	}

	if ((isAskGraph && name === "ask") || (!isAskGraph && name === "trade")) {
		return (
			<circle
				key={index}
				cx={xScale(data.date)}
				cy={yScale(data.value)}
				r={2}
				fill="white"
				stroke={"#175963"}
				strokeWidth="1.5"
			/>
		);
	}

	if (isAskGraph && name === "trade") {
		const x = xScale(data.date);
		const y = yScale(data.value);
		const ICON_WIDTH = 6;
		const ICON_HEIGHT = 6;
		const ICON_INNER_WIDTH = 3;
		const ICON_INNER_HEIGHT = 3;
		const STROKE_WIDTH = 1.5;
		return (
			<g transform={`translate(${x - ICON_WIDTH / 2}, ${y - ICON_HEIGHT / 2})`}>
				<rect
					width={ICON_WIDTH}
					height={ICON_HEIGHT}
					fill="white"
					stroke="#4c5564"
					strokeWidth={STROKE_WIDTH}
				/>
				<rect
					x={ICON_INNER_WIDTH / 2}
					y={ICON_INNER_HEIGHT / 2}
					width={ICON_INNER_WIDTH}
					height={ICON_INNER_HEIGHT}
					fill="#4c5564"
				/>
			</g>
		);
	}

	if (!isAskGraph && name === "ask") {
		return (
			<polygon
				key={`${name}-marks-${index}`}
				points={getDownwardEquilateralTrianglePoints({
					x: xScale(data.date),
					y: yScale(data.value),
					baseLength,
					height,
				})}
				fill="white"
				stroke="#AF1623"
				strokeWidth="1.5"
			/>
		);
	}

	return <></>;
};
