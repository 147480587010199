import * as d3 from "d3";
import { useMemo } from "react";
import { Data, Dimensions, IntervalData } from "../pricing-graph.types";

interface ToggleIndicator {
	ask: boolean;
	bid: boolean;
	trades: boolean;
}

interface Props {
	mouseX: number | null;
	data: IntervalData;
	dimensions: Dimensions;
	xScale: d3.ScaleTime<number, number, never>;
	yScale: d3.ScaleLinear<number, number, never>;
	isAskGraph: boolean;
	toggleIndicator: ToggleIndicator;
}

const usePricingGraphLegend = ({
	mouseX,
	data,
	dimensions,
	xScale,
	yScale,
	isAskGraph,
	toggleIndicator,
}: Props) => {
	const invertedDate = useMemo(() => {
		return mouseX !== null && mouseX >= 0 && mouseX <= dimensions.boundedWidth
			? xScale.invert(mouseX)
			: null;
	}, [mouseX, dimensions.boundedWidth, xScale]);

	const sortTwoPointsByDateAndValue = (a: Data, b: Data) => {
		const twoPoints = [a, b].sort((a, b) => {
			const dateComparison = a.date.valueOf() - b.date.valueOf();
			if (dateComparison === 0) {
				return b.value - a.value;
			}
			return dateComparison;
		});
		return twoPoints;
	};

	// Calculate closest data point
	const closestDataPointResult = useMemo(() => {
		const calculateclosestDataPoint = (array: Data[]) => {
			return array.reduce((closest, current) => {
				const closestDiff = Math.abs(
					closest.date.valueOf() - invertedDate!.getTime(),
				);
				const currentDiff = Math.abs(
					current.date.valueOf() - invertedDate!.getTime(),
				);
				return currentDiff < closestDiff ? current : closest;
			});
		};

		let closestDataPoint: Data | null = null;
		let closestDataPointIsLine = false;

		if (invertedDate === null) {
			// (if invertedDate is null
			// due to cursor not on graph then closest data is set to latest)
			let cursorData: Data[] | null = [];

			if (isAskGraph) {
				// Ask Graph
				if (data?.ask?.length > 0) cursorData = data.ask;
				else {
					cursorData =
						data?.bid?.length > 0 && toggleIndicator.bid ? data.bid : null;
				}
			} else {
				// Market Graph
				if (data?.trade?.length > 0) cursorData = data.trade;
				else if (
					toggleIndicator.ask &&
					data?.ask?.length > 0 &&
					toggleIndicator.bid &&
					data?.bid?.length > 0
				) {
					const ask = data.ask[data.ask.length - 1];
					const bid = data.bid[data.bid.length - 1];
					cursorData = ask.value > bid.value ? data.ask : data.bid;
				} else if (toggleIndicator.ask && data?.ask?.length > 0) {
					cursorData = data.ask;
				} else if (toggleIndicator.bid && data?.bid?.length > 0) {
					cursorData = data.bid;
				} else cursorData = null;
			}
			closestDataPoint = cursorData ? cursorData[cursorData.length - 1] : null;
		} else if (isAskGraph) {
			// Ask Graph
			let askPoint = null;
			let bidPoint = null;
			let tradePoint = null;
			if (data?.ask?.length > 0) askPoint = calculateclosestDataPoint(data.ask);
			if (data?.bid?.length > 0) bidPoint = calculateclosestDataPoint(data.bid);
			if (data?.trade?.length > 0)
				tradePoint = calculateclosestDataPoint(data.trade);
			const bidPointOn = toggleIndicator.bid ? bidPoint : null;
			const tradePointOn = toggleIndicator.trades ? tradePoint : null;

			if (!askPoint && !bidPointOn && !tradePointOn) closestDataPoint = null;
			else if (!askPoint && !bidPointOn) closestDataPoint = null;
			else if (!askPoint && bidPointOn) closestDataPoint = bidPointOn;
			else if (askPoint && !bidPointOn && !tradePointOn) {
				closestDataPoint = askPoint;
			} else if (askPoint && !bidPointOn && tradePointOn) {
				closestDataPoint =
					askPoint?.date.valueOf() === tradePoint?.date.valueOf()
						? askPoint
						: calculateclosestDataPoint([askPoint, tradePoint!]);
			} else if (askPoint && bidPointOn && !tradePointOn) {
				closestDataPoint =
					askPoint?.date.valueOf() === bidPoint?.date.valueOf()
						? askPoint
						: calculateclosestDataPoint([askPoint, bidPoint!]);
			} else {
				closestDataPoint = calculateclosestDataPoint(
					sortTwoPointsByDateAndValue(bidPointOn!, tradePointOn!),
				);
				closestDataPoint.date.valueOf() === askPoint!.date.valueOf()
					? (closestDataPoint = askPoint)
					: (closestDataPoint = calculateclosestDataPoint([
							closestDataPoint,
							askPoint!,
						]));
			}

			if (askPoint) {
				closestDataPointIsLine = closestDataPoint === askPoint ? true : false;
			}
		} else {
			// Market Graph
			let askPoint;
			let bidPoint;
			let tradePoint;
			if (data?.ask?.length > 0) askPoint = calculateclosestDataPoint(data.ask);
			if (data?.bid?.length > 0) bidPoint = calculateclosestDataPoint(data.bid);
			if (data?.trade?.length > 0)
				tradePoint = calculateclosestDataPoint(data.trade);
			const askPointOn = toggleIndicator.ask ? askPoint : null;
			const bidPointOn = toggleIndicator.bid ? bidPoint : null;

			if (!tradePoint) {
				if (!askPointOn && !bidPointOn) closestDataPoint = null;
				else if (askPointOn) closestDataPoint = askPointOn;
				else if (bidPointOn) closestDataPoint = bidPointOn;
				else {
					closestDataPoint = calculateclosestDataPoint(
						sortTwoPointsByDateAndValue(askPointOn!, bidPointOn!),
					);
				}
			} else if (!askPoint || !bidPoint)
				throw new Error("Missing ask or bid points");
			else if (!askPointOn && !bidPointOn) {
				closestDataPoint = tradePoint;
			} else if (askPointOn && !bidPointOn) {
				closestDataPoint =
					tradePoint.date.valueOf() === askPoint.date.valueOf()
						? tradePoint
						: calculateclosestDataPoint([tradePoint, askPoint]);
			} else if (!askPointOn && bidPointOn) {
				closestDataPoint =
					tradePoint.date.valueOf() === bidPoint.date.valueOf()
						? tradePoint
						: calculateclosestDataPoint([tradePoint, bidPoint]);
			} else {
				closestDataPoint = calculateclosestDataPoint(
					sortTwoPointsByDateAndValue(askPointOn!, bidPointOn!),
				);
				closestDataPoint.date.valueOf() === tradePoint.date.valueOf()
					? (closestDataPoint = tradePoint)
					: (closestDataPoint = calculateclosestDataPoint([
							closestDataPoint,
							tradePoint,
						]));
			}
			if (tradePoint) {
				closestDataPointIsLine = closestDataPoint === tradePoint ? true : false;
			}
		}
		return { closestDataPoint, closestDataPointIsLine };
	}, [
		invertedDate,
		isAskGraph,
		data.ask,
		data.bid,
		data.trade,
		toggleIndicator.bid,
		toggleIndicator.ask,
		toggleIndicator.trades,
	]);

	const { closestDataPoint, closestDataPointIsLine } = closestDataPointResult;

	// For legends of both graph types (ask and market)
	const legends = useMemo(() => {
		const result = [];
		if (closestDataPoint) {
			const ask =
				data.ask.find(
					(order) => order.date.valueOf() === closestDataPoint.date.valueOf(),
				) ?? null;
			const bid =
				data.bid.find(
					(order) => order.date.valueOf() === closestDataPoint.date.valueOf(),
				) ?? null;
			const trade =
				data.trade.find(
					(order) => order.date.valueOf() === closestDataPoint.date.valueOf(),
				) ?? null;

			if (toggleIndicator.ask && ask) {
				result.push({ data: ask, name: "Ask price" });
			}
			if (toggleIndicator.bid && bid) {
				result.push({ data: bid, name: "Bid price" });
			}
			if (toggleIndicator.trades && trade) {
				if (isAskGraph) {
					result.push({ data: trade, name: "Closed trade" });
				} else {
					result.unshift({ data: trade, name: "Closed trade" });
				}
			}
		}
		return result;
	}, [closestDataPoint, data, isAskGraph, toggleIndicator]);

	// For date tooltip component
	let xDataTranslation = null;
	let yDataTranslation = null;

	if (closestDataPoint) {
		xDataTranslation = xScale(closestDataPoint.date);
		yDataTranslation = yScale(closestDataPoint.value);
	}

	return {
		invertedDate,
		legends,
		closestDataPoint,
		closestDataPointIsLine,
		xDataTranslation,
		yDataTranslation,
	};
};

export default usePricingGraphLegend;
