import { Dimensions } from "./pricing-graph.types";

interface Props {
	x: number;
	y?: number;
	dimensions: Dimensions;
}

export const VerticalLine = ({ x, y = 0, dimensions }: Props) => {
	return (
		<line
			x1={x}
			y1={y}
			x2={x}
			y2={dimensions.boundedHeight}
			stroke="#FE9418"
			strokeWidth={1}
		/>
	);
};
