import {
	getDownwardEquilateralTrianglePoints,
	getUpwardEquilateralTrianglePoints,
} from "./marks";

interface Props {
	toggleIndicator: {
		ask: boolean;
		bid: boolean;
		trades: boolean;
	};
	onChange: React.ChangeEventHandler;
	isAskGraph: boolean;
}

const Toggle = ({ toggleIndicator, onChange, isAskGraph }: Props) => {
	if (isAskGraph) {
		return (
			<div
				className="inline-flex gap-x-4 md:gap-x-8 mt-4 justify-between md:justify-normal"
				role="group"
			>
				<label className="inline-flex items-center cursor-default">
					<input
						type="checkbox"
						checked={toggleIndicator.ask}
						className="sr-only peer"
						name="toggle-ask"
						onChange={onChange}
						disabled
					/>
					<div className="relative w-8 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-green-300 dark:peer-focus:ring-green-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[0px] after:start-[0px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-alta-light-green" />
					<Circle text="Ask" />
				</label>

				<label className="inline-flex items-center cursor-pointer">
					<input
						type="checkbox"
						checked={toggleIndicator.bid}
						className="sr-only peer"
						name="toggle-bid"
						onChange={onChange}
					/>
					<div className="relative w-8 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-green-300 dark:peer-focus:ring-green-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[0px] after:start-[0px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-alta-light-green" />
					<UpwardTriangle text="Bid" />
				</label>

				<label className="inline-flex items-center cursor-pointer">
					<input
						type="checkbox"
						checked={toggleIndicator.trades}
						className="sr-only peer"
						name="toggle-closed-trades"
						onChange={onChange}
					/>
					<div className="relative w-8 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-green-300 dark:peer-focus:ring-green-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[0px] after:start-[0px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-alta-light-green" />
					<Trade text="Closed trades" />
				</label>
			</div>
		);
	}
	return (
		<div
			className="inline-flex gap-x-4 md:gap-x-8 mt-4 justify-between md:justify-normal"
			role="group"
		>
			<label className="inline-flex items-center cursor-default">
				<input
					type="checkbox"
					checked={toggleIndicator.trades}
					className="sr-only peer"
					name="toggle-closed-trades"
					onChange={onChange}
					disabled
				/>
				<div className="relative w-8 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-green-300 dark:peer-focus:ring-green-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[0px] after:start-[0px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-alta-light-green" />
				<Circle text="Market price" />
			</label>

			<label className="inline-flex items-center cursor-pointer">
				<input
					type="checkbox"
					checked={toggleIndicator.ask}
					className="sr-only peer"
					name="toggle-ask"
					onChange={onChange}
				/>
				<div className="relative w-8 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-green-300 dark:peer-focus:ring-green-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[0px] after:start-[0px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-alta-light-green" />
				<DownwardTriangle text="Ask" />
			</label>

			<label className="inline-flex items-center cursor-pointer">
				<input
					type="checkbox"
					checked={toggleIndicator.bid}
					className="sr-only peer"
					name="toggle-bid"
					onChange={onChange}
				/>
				<div className="relative w-8 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-green-300 dark:peer-focus:ring-green-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[0px] after:start-[0px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-alta-light-green" />
				<UpwardTriangle text="Bid" />
			</label>
		</div>
	);
};

export default Toggle;

const Circle = ({ text }: { text: string }) => {
	return (
		<span className="ms-1 text-xs md:text-sm font-medium text-gray-900 dark:text-gray-900 inline-flex items-center">
			<svg height={20} width={20}>
				<circle
					cy={10}
					cx={10}
					r={2.5}
					fill="white"
					stroke={"#175963"}
					strokeWidth="2"
				/>
			</svg>
			{text}
		</span>
	);
};
const UpwardTriangle = ({ text }: { text: string }) => {
	return (
		<span className="ms-1 text-xs md:text-sm font-medium text-gray-900 dark:text-gray-900 inline-flex items-center">
			<svg height={20} width={20}>
				<polygon
					transform="translate(5,5.5)"
					points={getUpwardEquilateralTrianglePoints()}
					fill="white"
					stroke="#249567"
					strokeWidth="1.5"
				/>
			</svg>
			{text}
		</span>
	);
};
const DownwardTriangle = ({ text }: { text: string }) => {
	return (
		<span className="ms-1 text-xs md:text-sm font-medium text-gray-900 dark:text-gray-900 inline-flex items-center">
			<svg height={20} width={20}>
				<polygon
					transform="translate(4,4.5)"
					points={getDownwardEquilateralTrianglePoints()}
					fill="white"
					stroke="#AF1623"
					strokeWidth="1.5"
				/>
			</svg>
			{text}
		</span>
	);
};
const Trade = ({ text }: { text: string }) => {
	return (
		<span className="ms-2 text-xs md:text-sm font-medium text-gray-900 dark:text-gray-900 inline-flex items-center">
			<svg height={15} width={15} className="" fill="none">
				<g transform="translate(0,2)">
					<rect x="2" y="2" width="6" height="6" stroke="#4c5564" />
					<rect x="3.5" y="3.5" width="3" height="3" fill="#4c5564" />
				</g>
			</svg>
			{text}
		</span>
	);
};
