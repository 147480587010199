import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";

const TextWithClipboardIcon = ({
	text,
	id,
	width = "14",
	truncate = true,
}: {
	text: string;
	id?: string;
	width?: string;
	truncate?: boolean;
}) => (
	<div className="flex flex-row bg-gray-100 px-1 gap-0.5 rounded border border-gray-300 items-center w-fit">
		<p
			id={id}
			className={`leading-4 text-gray-900 text-[10px] ${truncate ? `truncate text-ellipsis w-${width}` : "whitespace-normal"}`}
		>
			{text}
		</p>
		<ClipboardDocumentIcon
			stroke="#1F2937"
			strokeWidth={2}
			onClick={() => navigator.clipboard.writeText(text)}
			className="size-3 cursor-pointer"
		/>
	</div>
);

export { TextWithClipboardIcon };
