import { Moment } from "moment";

export interface SeriesData {
	date: string;
	value: number;
	isExtrapolated?: boolean;
}

export interface Data {
	date: Moment;
	value: number;
	isExtrapolated?: boolean;
}

export interface SeriesIntervalData {
	ask: SeriesData[];
	bid: SeriesData[];
	trade: SeriesData[];
}

export interface IntervalData {
	ask: Data[];
	bid: Data[];
	trade: Data[];
}

export enum GRAPH_TYPE {
	ASK = "ask",
	MARKET = "market",
}

export interface GraphData {
	listingDate?: string | null;
	showGraph: boolean;
	graphType: GRAPH_TYPE;
	daily: SeriesIntervalData;
	weekly: SeriesIntervalData;
	monthly: SeriesIntervalData;
}

export interface Dimensions {
	boundedHeight: number;
	boundedWidth: number;
	marginTop: number;
	marginRight: number;
	marginBottom: number;
	marginLeft: number;
	width: number;
	height: number;
}

export enum PERIOD {
	THREE_MONTHS = "3M",
	SIX_MONTHS = "6M",
	YEAR_TO_DATE = "YTD",
	MAX = "MAX",
}

export enum INTERVAL {
	DAILY = "daily",
	WEEKLY = "weekly",
	MONTHLY = "monthly",
}
