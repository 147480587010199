import * as d3 from "d3";
import { useMemo } from "react";
import { addThousandSeparatorWithDP } from "./../../../lib/number";
import { Dimensions } from "./pricing-graph.types";

interface Props {
	range: number[];
	pixelsPerTick?: number;
	dimensions: Dimensions;
	tickLength?: number;
	showAxisLine?: boolean;
	showTickLine?: boolean;
	scale: d3.ScaleLinear<number, number, never>;
}

export const YAxis = ({
	range,
	pixelsPerTick = 40,
	dimensions,
	tickLength = 6,
	showAxisLine = false,
	showTickLine = false,
	scale,
}: Props) => {
	const ticks = useMemo(() => {
		const numberOfTicksTarget = Math.max(
			1,
			Math.floor(range[1] / pixelsPerTick),
		);

		return scale.ticks(numberOfTicksTarget).map((value) => ({
			value,
			yOffset: scale(value),
		}));
	}, [dimensions]);

	const d = ["M", 0, 0, "v", -dimensions.boundedHeight];

	return (
		<g>
			{showAxisLine && (
				<path d={d.join(" ")} fill="none" stroke="currentColor" />
			)}
			{ticks.map(({ value, yOffset }) => (
				<g key={value} transform={`translate(0, ${yOffset})`}>
					{showTickLine && <line x2={tickLength} stroke="currentColor" />}
					<text
						key={value}
						style={{
							fontSize: "10px",
							textAnchor: "end",
							transform: `translate(-3px, 3px)`,
						}}
					>
						{addThousandSeparatorWithDP(Number(value), 2)}
					</text>
					<line
						x1={0}
						x2={dimensions.boundedWidth}
						stroke="#d3d3d3"
						strokeWidth={0.5}
					/>
				</g>
			))}
		</g>
	);
};
