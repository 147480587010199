import * as React from "react";

import { cn } from "../../lib/utils";

const Avatar = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn(
			"bg-alta-dark-green w-8 h-8 rounded-full text-white text-base flex items-center justify-center",
			className,
		)}
		{...props}
	/>
));
Avatar.displayName = "Avatar";

export { Avatar };
