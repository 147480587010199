import { Dispatch, MouseEvent, SetStateAction } from "react";
import { INTERVAL, PERIOD } from "./pricing-graph.types";

interface Props {
	periodSelected: PERIOD;
	setPeriodSelected: Dispatch<SetStateAction<PERIOD>>;
	zoomAndPanToTimePeriod: (period: PERIOD, interval: INTERVAL) => void;
	options?: PERIOD[];
	setIntervalSelected: Dispatch<SetStateAction<INTERVAL>>;
}

export const TimePeriod = ({
	periodSelected,
	setPeriodSelected,
	zoomAndPanToTimePeriod,
	options,
	setIntervalSelected,
}: Props) => {
	const availableOptions: PERIOD[] = options ?? Object.values(PERIOD);

	const handleClick = (e: MouseEvent<HTMLDivElement>) => {
		const button = e.target as HTMLInputElement;
		const period = button.outerText as PERIOD;
		setPeriodSelected(period);

		let interval = INTERVAL.DAILY;
		if (period === PERIOD.MAX) interval = INTERVAL.MONTHLY;
		else if (period === PERIOD.YEAR_TO_DATE) interval = INTERVAL.WEEKLY;
		else if (period === PERIOD.SIX_MONTHS) interval = INTERVAL.WEEKLY;
		setIntervalSelected(interval);
		zoomAndPanToTimePeriod(period, interval);
	};

	return (
		<div className="flex w-full justify-between md:justify-end md:gap-x-1">
			{Object.values(PERIOD).map((period) => {
				const isEnabled = availableOptions.includes(period);

				return (
					<div
						key={period}
						className={`rounded-md px-4 py-1.5 text-sm  ${
							periodSelected === period && "bg-[#d6eaed]"
						} ${
							isEnabled
								? "cursor-pointer text-[#485564] hover:bg-[#d6eaed]"
								: "cursor-default text-slate-300"
						}`}
						onClick={isEnabled ? handleClick : () => {}}
					>
						{period}
					</div>
				);
			})}
		</div>
	);
};
