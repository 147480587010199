import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const getFileTypeFromFileName = (fileName: string) => {
	const hasFileExtension = fileName.lastIndexOf(".") >= 0;
	const fileExtension = hasFileExtension
		? fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length)
		: "";
	return fileExtension.toUpperCase();
};

export const formatFileSizesWithPrefix = (size: number) => {
	if (size === 0) return "0 Bytes";
	const k = 1024;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
	const i = Math.floor(Math.log(size) / Math.log(k));
	return parseFloat((size / Math.pow(k, i)).toFixed(0)) + " " + sizes[i];
};

export const capitalizeFirstChar = (str: string) => {
	if (str) return str[0].toUpperCase() + str.toLowerCase().slice(1);
	else return "";
};
