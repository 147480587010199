import { format, addDays, isSameDay, startOfMonth, startOfWeek } from 'date-fns';
import { SeriesData } from '../pricing-graph.types';

// NOTE: all TODO here can be done later, its just generating mock data

const startDate = new Date('2023-01-01');
const endDate = new Date();
const fluctuationRange = 0.03; // 3% fluctuation for ask prices
const actualChangeProbability = 0.1; // 10% chance of actual price change
const maxBidsPerMonth = 5; // At most 5 bids in a month

const generateDailyAskValues = (start: Date, end: Date, initialValue: number): SeriesData[] => {
    const askValues: SeriesData[] = [];
    let currentDate = start;
    let currentValue = initialValue;

    while (currentDate <= end) {
        const isExtrapolated = Math.random() > actualChangeProbability;
        if (!isExtrapolated) {
            const fluctuation = (Math.random() - 0.5) * fluctuationRange * currentValue;
            currentValue += fluctuation;
            currentValue = Math.max(currentValue, 1); // Ensure the value doesn't go below 1
        }
        askValues.push({
            date: format(currentDate, 'yyyy-MM-dd'),
            value: parseFloat(currentValue.toFixed(2)), // Ensure value is a number
            isExtrapolated,
        });
        currentDate = addDays(currentDate, 1);
    }

    return askValues;
};

const generateBidValues = (askValues: SeriesData[]): SeriesData[] => {
    const bidValues: SeriesData[] = [];
    let currentMonth = new Date(askValues[0].date).getMonth();
    let bidsThisMonth = 0;

    askValues.forEach((ask) => {
        const askDate = new Date(ask.date);
        if (askDate.getMonth() !== currentMonth) {
            currentMonth = askDate.getMonth();
            bidsThisMonth = 0;
        }

        if (bidsThisMonth < maxBidsPerMonth && Math.random() > 0.7) {
            const fluctuation = (Math.random() - 0.5) * 0.02 * ask.value; // Use ask.value directly as it's already a number
            const bidValue = ask.value + fluctuation;
            bidValues.push({
                date: ask.date,
                value: parseFloat(bidValue.toFixed(2)), // Ensure value is a number
            });
            bidsThisMonth++;
        }
    });

    return bidValues;
};

const generateTradeValues = (askValues: SeriesData[], bidValues: SeriesData[]): SeriesData[] => {
    const tradeValues: SeriesData[] = [];

    askValues.forEach((ask) => {
        const bid = bidValues.find((bid) => isSameDay(new Date(bid.date), new Date(ask.date)));
        if (bid && bid.value >= ask.value) {
            tradeValues.push({
                date: ask.date,
                value: ask.value, // Trade occurs at the ask price
            });
        }
    });

    return tradeValues;
};

// TODO: Still wrong on weekly
const calculateWeeklyAverages = (dailyData: SeriesData[], startDate: Date): SeriesData[] => {
    const weeklyAverages: SeriesData[] = [];
    let currentWeek: SeriesData[] = [];
    let currentDate = new Date(startDate);

    dailyData.forEach((day, index) => {
        const dayDate = new Date(day.date);

        if (dayDate >= currentDate && dayDate < addDays(currentDate, 7)) {
            currentWeek.push({
                date: day.date,
                value: day.value,
                isExtrapolated: day.isExtrapolated,
            });
        } else {
            if (currentWeek.length > 0) {
                const isExtrapolated = currentWeek.some(day => !day.isExtrapolated);
                const average = currentWeek.reduce((acc, val) => acc + val.value, 0) / currentWeek.length;

                weeklyAverages.push({
                    date: format(startOfWeek(currentDate, { weekStartsOn: 1 }), 'yyyy-MM-dd'),
                    value: parseFloat(average.toFixed(2)), // Ensure value is a number
                    isExtrapolated,
                });
            }

            currentDate = addDays(currentDate, 7);
            currentWeek = [];

            currentWeek.push({
                date: day.date,
                value: day.value,
                isExtrapolated: day.isExtrapolated,
            });
        }

        if (index === dailyData.length - 1 && currentWeek.length > 0) {
            const isExtrapolated = currentWeek.some(day => !day.isExtrapolated);
            const average = currentWeek.reduce((acc, val) => acc + val.value, 0) / currentWeek.length;

            weeklyAverages.push({
                date: format(startOfWeek(currentDate, { weekStartsOn: 1 }), 'yyyy-MM-dd'),
                value: parseFloat(average.toFixed(2)), // Ensure value is a number
                isExtrapolated,
            });
        }
    });

    return weeklyAverages;
};

// TODO: Still wrong on monthly
const calculateMonthlyAverages = (dailyData: SeriesData[], startDate: Date): SeriesData[] => {
    const monthlyAverages: SeriesData[] = [];
    let currentMonth: SeriesData[] = [];
    const currentDate = new Date(startDate);

    dailyData.forEach((day, index) => {
        const dayDate = new Date(day.date);

        if (dayDate.getMonth() === currentDate.getMonth()) {
            currentMonth.push({
                date: day.date,
                value: day.value,
                isExtrapolated: day.isExtrapolated,
            });
        }

        if (dayDate.getMonth() !== currentDate.getMonth() || index === dailyData.length - 1) {
            if (currentMonth.length > 0) {
                const isExtrapolated = currentMonth.some(day => !day.isExtrapolated);
                const average = currentMonth.reduce((acc, val) => acc + val.value, 0) / currentMonth.length;

                monthlyAverages.push({
                    date: format(startOfMonth(currentDate), 'yyyy-MM-dd'),
                    value: parseFloat(average.toFixed(2)), // Ensure value is a number
                    isExtrapolated,
                });
            }

            currentDate.setMonth(currentDate.getMonth() + 1);
            currentDate.setDate(1);
            currentMonth = [];
        }
    });

    return monthlyAverages;
};

const initialAskValue = 2000;
const askValues = generateDailyAskValues(startDate, endDate, initialAskValue);
const bidValues = generateBidValues(askValues);
const tradeValues = generateTradeValues(askValues, bidValues);
const weeklyAskAverages = calculateWeeklyAverages(askValues, startDate);
const weeklyBidAverages = calculateWeeklyAverages(bidValues, startDate);
const weeklyTradeAverages = calculateWeeklyAverages(tradeValues, startDate);
const monthlyAskAverages = calculateMonthlyAverages(askValues, startDate);
const monthlyBidAverages = calculateMonthlyAverages(bidValues, startDate);
const monthlyTradeAverages = calculateMonthlyAverages(tradeValues, startDate);

export const graphData: any = {
    showGraph: true,
    graphType: 'ask',
    listingDate: '2023-09-30T00:00:00.000Z',
    daily: {
        ask: askValues,
        bid: bidValues,
        trade: tradeValues,
    },
    weekly: {
        ask: weeklyAskAverages,
        bid: weeklyBidAverages,
        trade: weeklyTradeAverages,
    },
    monthly: {
        ask: monthlyAskAverages,
        bid: monthlyBidAverages,
        trade: monthlyTradeAverages,
    },
};
