interface Props {
	text: string;
	x: number;
}

export const Label = ({ text, x }: Props) => {
	const LABEL_HEIGHT = 20;
	const LABEL_WIDTH = 85;

	return (
		<g transform={`translate(${x - LABEL_WIDTH / 2}, ${- LABEL_HEIGHT / 2})`}>
			<rect
				rx="4"
				x="0"
				y="0"
				width={LABEL_WIDTH}
				height={LABEL_HEIGHT}
				fill="#FE9418"
			/>

			<text
				x={LABEL_WIDTH / 2}
				y={LABEL_HEIGHT / 2 + 1}
				textAnchor="middle"
				dominantBaseline="middle"
				fill="white"
				fontSize="10"
				fontWeight="bold"
			>
				{text}
			</text>
		</g>
	);
};
