import * as d3 from "d3";
import { addThousandSeparatorWithDP } from "./../../../lib/number";
import { Dimensions } from "./pricing-graph.types";

interface Props {
	mouseY: number;
	dimensions: Dimensions;
	scale: d3.ScaleLinear<number, number, never>;
	yAxisValueLeftOffset: number;
}

export const PriceIndicator = ({
	mouseY,
	dimensions,
	scale,
	yAxisValueLeftOffset,
}: Props): JSX.Element => {
	const closest =
		mouseY > 0 && mouseY < dimensions.boundedHeight
			? scale.clamp(true).invert(mouseY)
			: null;

	// get text width to calculate rect
	const element = document.createElement("canvas");
	const context = element.getContext("2d")!;
	context.font = "10px";

	// get the highest scale of y axis and add 2 digits
	const rectWidth = yAxisValueLeftOffset;

	if (!closest) return <></>;

	return (
		<g transform={`translate(-${rectWidth}, 0)`}>
			<line
				x1={0}
				y1={mouseY}
				x2={dimensions.boundedWidth + yAxisValueLeftOffset}
				y2={mouseY}
				stroke="#9CA3AF"
				strokeWidth={0.5}
				strokeDasharray="3,3"
			/>
			<g transform={`translate(${0}, ${mouseY - 10})`}>
				<g transform={`translate(${rectWidth - 2.5}, 5)`}>
					<svg
						width="10"
						height="10"
						viewBox="0 0 10 10"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M10 5L0.25 9.33013L0.25 0.669873L10 5Z" fill="#9CA3AF" />
					</svg>
				</g>

				<svg
					width={rectWidth}
					height="52"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect
						style={{
							filter:
								"drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.04)) drop-shadow(0px 30px 18px rgba(0, 0, 0, 0.02))",
						}}
						width={rectWidth}
						height="20"
						rx="3"
						fill="#9CA3AF"
					/>
					<text
						fill="white"
						dominantBaseline="middle"
						fontSize="10"
						x={rectWidth / 2}
						y="11"
						textAnchor="middle"
					>
						{addThousandSeparatorWithDP(Number(closest), 2)}
					</text>
				</svg>
			</g>
		</g>
	);
};
